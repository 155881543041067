import React from "react";

import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/welding/brands.png"

import p1 from "assets/img/products/welding/1.jpg";
import p2 from "assets/img/products/welding/2.jpg";
import p3 from "assets/img/products/welding/3.jpg";
import p4 from "assets/img/products/welding/4.jpg";
import p5 from "assets/img/products/welding/5.jpg";
import p6 from "assets/img/products/welding/6.jpg";
import p7 from "assets/img/products/welding/7.jpg";
import p8 from "assets/img/products/welding/8.jpg";
// import p9 from "assets/img/products/welding/9.jpg";
// import p10 from "assets/img/products/welding/10.jpeg";
// import p11 from "assets/img/products/welding/11.png";

import s1 from "assets/img/products/welding/slider/1.jpg";
import s2 from "assets/img/products/welding/slider/2.jpg";
import s3 from "assets/img/products/welding/slider/3.jpg";

export default () => (
  <ProductPage prod="Welding" url="welding" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2},
    { img: s3}
]

const products = [
    { name: 'Arc Welding Equipment',      img: p1 },
    { name: 'Cutting Automation',          img: p2 },
    { name: 'PPE & Accessories',            img: p3 },
    { name: 'Plasma',           img: p4 },
    { name: 'Filler Metals',           img: p5 },
    { name: 'Gas Equipment',             img: p6 },
    { name: 'Arc Gouging (CAC-A) & Exothermic Cutting',img: p7 },
    { name: 'Welding Automation & Robotics',   img: p8 }
]